<template>
    <div class="index-content">
        <!-- banner -->
        <div class="index-banner">
            <el-carousel :interval="5000" arrow="always">
                <el-carousel-item v-for="(item, index) in bannerArr" :key="index" :style="{ background: item.bg }">
                    <!-- <h3 :style="item.bgInfo"></h3> -->
                    <img :src="item.src" alt="banner">
                    <!-- <img src="../../../assets/images/homeBanner_01.jpg" alt="banner" width="1200" height="470"> -->
                </el-carousel-item>
            </el-carousel>
        </div>
        
        <!-- 课堂教学 -->
        <div class="m-lesson-teach">
            <div class="wrap1200 posra">
                <h4 class="tit-lesson-teach"><i class="el-icon-message-solid"></i> 课堂教学 <span>教师上课好帮手</span></h4>
                <ol class="clearfix">
                    <li>
                        <a href="http://www.eyyb.vip/yyb/index.html" target="_blank">
                            <img src="../../../assets/images/index-english.jpg" alt="">
                            <p><a href="http://www.eyyb.vip/yyb/index.html"  target="_blank" class="c-btn">立即进入<img src="../../../assets/images/sh1.png" alt="" class="link-point"></a></p>
                        </a>
                    </li>
                    <li>
                        <a href="https://yw.eyyb.vip/login" target="_blank">
                            <img src="../../../assets/images/index-loadChinese.jpg" alt="">
                            <p><a href="https://yw.eyyb.vip/login"  target="_blank" class="c-btn c-btn-orange">立即进入<img src="../../../assets/images/sh2.png" alt="" class="link-point"></a> </p>
                        </a>
                    </li>
                </ol>
                <img src="../../../assets/images/index-loadTeacher.jpg" alt="" class="load-teacher">
            </div>
        </div>
        <!-- 专家引领 -->
        <div class="index-experts posra m-indexPart">
            <div class="bg-gray"></div>
            <div class="wrap1200 posra">
                <div class="c-index-title">专家引领</div>
                <ul class="c-index-mInfors">
                    <li @click="linkToexpert(item)" v-for="(item, index) in expertsList" v-if="index < 3">
                        <a href="javascript:;">
                            <img :src="'https://oss.eyyb.vip/'+item.bgkImg" alt="" height="200">
                        </a>
                        <div class="more-infors">
                            <h5><a href="javascript:;">{{item.name}}</a></h5>
                            <p><i class="el-icon-view"></i> {{item.playTimesZh}}</p>
                        </div>
                        
                    </li>
                </ul>
                <a href="javascript:;" class="list-more s-green" @click="linkToexpertList()">更多</a>
            </div>
        </div>
        <!-- 教师教研 -->
        <div class="index-studys posra m-indexPart">
            <div class="wrap1200 posra">
                <div class="c-index-title">教师教研</div>
                <ul class="c-index-titleSecond">              
                    <li v-for="item in moduleList.teachingModule" @click="linkToList(4, '', item.id, item.name)" :key="item.id">{{item.name}}</li>
                    <!-- <li>示范课</li>
                    <li>比赛活动</li> -->
                </ul>
                <ul class="c-index-mInfors">
                    <li v-for="(item,index) in teachingModule" v-if="index < 3">
                        <a href="javascript:;" @click="linkToDetails(4, item)">
                            <img :src="'https://oss.eyyb.vip/'+item.bgkImg" alt="" height="200">
                        </a>
                        <div class="more-infors">
                            <h5><a href="javascript:;"  @click="linkToDetails(4, item)">{{item.name}}</a></h5>
                            <p><i class="el-icon-view"></i> {{item.playTimesZh}}次</p>
                        </div>
                        
                    </li>
                </ul>
                <a href="javascript:;" class="list-more s-green" @click="linkToList(4, teachingModule[0].moduleId)">更多</a>
            </div>
        </div>
        <!-- 教研文萃 -->
        <div class="index-elite posra m-indexPart">
            <div class="wrap1200 posra">
                <div class="c-index-title">教研文萃</div>
                <ul class="c-index-titleSecond">   
                    <li v-for="item in moduleList.articleModule" @click="linkToList(8, '', item.id, item.name)" :key="item.id">{{item.name}}</li>               
                    <!-- <li>教学反思</li>
                    <li>教育叙事</li>
                    <li>教材解读</li> -->
                </ul>
                <ul class="c-index-mInfors">
                    <li v-for="(item,index) in articleModule" v-if="index < 3">
                        <a  @click="linkToDetails(8, item)" href="javascript:;">
                            <img :src="'https://oss.eyyb.vip/'+item.bgkImg" alt="">
                        </a>
                        <div class="more-infors">
                            <h5><a  @click="linkToDetails(8, item)" href="javascript:;">{{item.name}}</a></h5>
                            <p>{{item.resourceFrom}} | {{item.publishDate}}&nbsp;&nbsp;{{item.moduleName}}</p>
                        </div>
                        
                    </li>
                    <!-- <li>
                        <a href="javascript:;">
                            <img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="">
                        </a>
                        <div class="more-infors">
                            <h5><a href="javascript:;">互联网与教师发展</a></h5>
                            <p>语文报 | 2019-01-01&nbsp;&nbsp;教材解读</p>
                        </div>
                        
                    </li> -->
                </ul>
                <a href="javascript:;" class="list-more s-green" @click="linkToList(8, articleModule[0].moduleId)">更多</a>
            </div>
        </div>
        <!-- 课后三点半 -->
        <div class="index-freeTimes posra m-indexPart">
            <div class="wrap1200 posra">
                <div class="c-index-title">课后三点半</div>
                <ul class="c-index-titleSecond">   
                    <li v-for="item in moduleList.alterClassModule" @click="linkToList(6, '', item.id, item.name)" :key="item.id">{{item.name}}</li>                          
                    <!-- <li>国学金典</li>
                    <li>安全教育</li>
                    <li>科技教育</li>
                    <li>家庭教育</li>
                    <li>读书会</li>
                    <li>创课课程</li> -->
                </ul>
                <ul class="c-index-mInfors">
                    <li v-for="(item,index) in alterClassModule" v-if="index < 3">
                        <a  @click="linkToDetails(6, item)" href="javascript:;">
                            <img :src="'https://oss.eyyb.vip/'+item.bgkImg" alt="">
                        </a>
                        <div class="more-infors">
                            <h5><a  @click="linkToDetails(6, item)" href="javascript:;">{{item.name}}</a></h5>
                            <p><i class="el-icon-view"></i> {{item.playTimesZh}}次</p>
                        </div>
                    </li>
                    <!-- <li>
                        <a href="javascript:;">
                            <img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="">
                        </a>
                        <div class="more-infors">
                            <h5><a href="javascript:;">互联网与教师发展</a></h5>
                            <p><i class="el-icon-view"></i> 1561次</p>
                        </div>
                        
                    </li> -->
                </ul>
                <a href="javascript:;" class="list-more s-green" @click="linkToList(6, alterClassModule[0].moduleId)">更多</a>
            </div>
        </div>
        <!-- 动态 -->
        <div class="index-elite posra m-indexPart index-dialogs">
            <div class="wrap1200 posra">
                <div class="c-index-title">动态</div>
                <ul class="c-index-titleSecond">  
                    <li v-for="item in moduleList.activeModule" @click="linkToList(7, '', item.id, item.name)" :key="item.id">{{item.name}}</li>                                      
                    <!-- <li style="color: #999;">课题</li>
                    <li style="color: #999;">活动</li>
                    <li style="color: #999;">资讯</li> -->
                </ul>
                <ul class="c-index-mInfors">
                    <li v-for="(item,index) in activeModule" v-if="index < 3">
                        <a  @click="linkToDetails(7, item)" href="javascript:;">
                            <img :src="'https://oss.eyyb.vip/'+item.bgkImg" alt="">
                        </a>
                        <div class="more-infors">
                            <h5><a  @click="linkToDetails(7, item)" href="javascript:;">{{item.name}}</a></h5>
                            <p><span class="s-tips s-tips-blue">{{item.moduleName}}</span> {{item.labels}} | {{item.publishDate}}&nbsp;&nbsp;{{item.resourceFrom}}</p>
                        </div>
                    </li>
                    <!-- <li>
                        <a href="javascript:;">
                            <img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="">
                        </a>
                        <div class="more-infors">
                            <h5><a href="javascript:;">互联网与教师发展</a></h5>
                            <p><span class="s-tips s-tips-blue">资讯</span> 语文报 | 2019-01-01&nbsp;&nbsp;教材解读</p>
                        </div>
                        
                    </li>
                    <li>
                        <a href="javascript:;">
                            <img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="">
                        </a>
                        <div class="more-infors">
                            <h5><a href="javascript:;">互联网与教师发展</a></h5>
                            <p><span class="s-tips s-tips-blue">资讯</span> 语文报 | 2019-01-01&nbsp;&nbsp;教材解读 <i class="icon-view el-icon-view"> 1234次</i></p>
                        </div>
                    </li> -->
                </ul>
                <a href="javascript:;" class="list-more s-green" @click="linkToList(7, activeModule[0].moduleId)">更多</a>
            </div>
        </div>
        <!-- 应用数据 -->
        <div class="index-datas posra m-indexPart">
            <div class="wrap1200 posra">
                <div class="c-index-title">应用数据</div>
                <ul class="c-datasList">
                    <li>
                        <h4>全国接入学校</h4>
                        <div class="numbers-infos">
                            <h6>3</h6>
                            <p>万所</p>
                        </div>
                    </li>
                    <li>
                        <h4>注册教师</h4>
                        <div class="numbers-infos">
                            <h6>10</h6>
                            <p>万名</p>
                        </div>
                    </li>
                    <li>
                        <h4>注册学生</h4>
                        <div class="numbers-infos">
                            <h6>190</h6>
                            <p>万名</p>
                        </div>
                    </li>
                    <li>
                        <h4>班级</h4>
                        <div class="numbers-infos">
                            <h6>17</h6>
                            <p>万</p>
                        </div>
                    </li>
                    <li>
                        <h4>日活用户</h4>
                        <div class="numbers-infos">
                            <h6>35</h6>
                            <p>万人</p>
                        </div>
                    </li>
                    <li>
                        <h4>作业人次</h4>
                        <div class="numbers-infos">
                            <h6>780</h6>
                            <p>万次</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 合作出版社 -->
        <div class="index-companies posra m-indexPart">
            <div class="wrap1200 posra">
                <div class="c-index-title">合作出版社</div>
                <ul>
                    <li>
                        <img src="../../../assets/images/index-com1.jpg" alt="">
                    </li>
                    <li>
                        <img src="../../../assets/images/index-com2.jpg" alt="">
                    </li>
                    <li>
                        <img src="../../../assets/images/index-com3.jpg" alt="">
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scope>
    @import '@/assets/css/pages/homepage.scss';
</style>

<script>
    import {mapGetters} from "vuex";
    import {setLocalStorage, getLocalStorage} from '@/utils/commonBase';
    export default {
        computed: { // vuex 储存
            ...mapGetters([
                'index'
            ])
        },
        data () {
            return {
                moduleList: {
                    teachingModule: [], // 教师教研（视频）
                    articleModule: [], // 教研文萃（文章）
                    alterClassModule: [], // 课后三点半（视频，同教师教研）
                    activeModule: [] // 动态（文章）
                }, // 大栏目下的小模块名字
                bannerArr: [{
                    bgInfo: {
                        background: "url("+require('../../../assets/images/homeBanner_01.jpg')+")"
                    },
                    src: require('../../../assets/images/homeBanner_01.jpg'),
                    bg:'#281a10'
                }, {
                    bgInfo: {
                        background: "url("+require('../../../assets/images/homeBanner_02.jpg')+")"
                    },
                    src: require('../../../assets/images/homeBanner_02.jpg'),
                    bg:'#e6cfb1'
                }, {
                    bgInfo: {
                        background: "url("+require('../../../assets/images/homeBanner_03.jpg')+")"
                    },
                    src: require('../../../assets/images/homeBanner_03.jpg'),
                    bg:'#413366'
                }, {
                    bgInfo: {
                        background: "url("+require('../../../assets/images/homeBanner_04.jpg')+")"
                    },
                    src: require('../../../assets/images/homeBanner_04.jpg'),
                    bg:'#49e9d4'
                }],
                expertsList: [],
                teachingModule: [], // 教师教研（视频）
                articleModule: [], // 教研文萃（文章）
                alterClassModule: [], // 课后三点半（视频，同教师教研）
                activeModule: [] // 动态（文章）
            } 
        },
        created () {
            this.loadInfos();
            this.loadExpert();
        },
        methods: {
            linkToList(index, moduleId, itemId, itemName) {  
                let obj = {
                    1: 'homepage',
                    2: 'eyyb',
                    3: 'classroomTeaching', // 课堂授课
                    4: 'teacherStudy', // 教师教研
                    5: 'testerTeacher', // 教师测评
                    6: 'timeFrees', // 课后三点半
                    7: 'trends', // 动态
                    8: 'teachingResearch', // 教案文萃
                    9: 'expertGuidance', // 专家引领
                }
                this.$router.push({name: obj[index], query:{moduleId, itemId, itemName}})
            },  
            loadExpert() {
                this.$http({
                    method: 'get',
                    url: '/api/yyb/v1/expModule/web/queryExpertModule',
                    params: {
                        appId: 'TruXBvv2TTO9IJjPDrmRIw',
                        appMenu: 'vOt-hyZFSVidUKPuxK0Msw',
                        timeStame: (new Date()).getTime()
                    }
                }).then(res => {
                    this.expertsList = res.data.obj;
                    for (let i = 0; i < this.expertsList.length; i++) {
                        const element = this.expertsList[i];
                        setLocalStorage(element.moduleId, element.permissions.join(','));
                    }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            loadInfos() {
                this.$http({
                    method: 'get',
                    url: '/api/yyb/v1/expModule/web/queryHomeData',
                    params: {
                        appId: 'TruXBvv2TTO9IJjPDrmRIw',
                        appMenu: 'vOt-hyZFSVidUKPuxK0Msw ',
                        userLevel: getLocalStorage('memberCode'),
                        timeStame: (new Date()).getTime()
					}
                }).then(res => {
                    console.log('res', res)
                    
                    this.teachingModule = [] // res.data.obj.teachingModule 教师教研
                    setLocalStorage(res.data.obj.teachingModule.moduleId, res.data.obj.teachingModule.permissions.join(','));
                    for (let index = 0; index < res.data.obj.teachingModule.data.length; index++) {
                        const element = res.data.obj.teachingModule.data[index];
                        this.moduleList.teachingModule.push({name:element.name, id: element.id});
                        if(element.entryList && element.entryList.length) {
                            element.entryList.forEach(ele => {
                                this.teachingModule.push({
                                    name: ele.name,
                                    bgkImg: ele.bgkImg,
                                    moduleId: res.data.obj.teachingModule.moduleId,
                                    moduleName: element.name,
                                    id: ele.id,
                                    seriesId: ele.seriesId,
                                    playTimesZh: ele.playTimesZh
                                })
                            });
                        }
                    }
                    this.articleModule = []; // res.data.obj.articleModule.data; 教研文萃（文章）
                    setLocalStorage(res.data.obj.articleModule.moduleId, res.data.obj.articleModule.permissions.join(','));
                    for (let index = 0; index < res.data.obj.articleModule.data.length; index++) {
                        const element = res.data.obj.articleModule.data[index];
                        this.moduleList.articleModule.push({name:element.name, id: element.id});
                        if(element.articleList && element.articleList.length) {
                            element.articleList.forEach(ele => {
                                this.articleModule.push({
                                    name: ele.name,
                                    bgkImg: ele.bgkImg,
                                    id: ele.id,
                                    moduleId: res.data.obj.articleModule.moduleId,
                                    moduleName: element.name,
                                    labels: ele.labels,
                                    seriesId: ele.seriesId,
                                    resourceFrom: ele.resourceFrom,
                                    playTimesZh: ele.playTimesZh
                                })
                            });
                        }
                    }
                    this.alterClassModule = []; // res.data.obj.alterClassModule.data; 课后三点半（视频，同教师教研）
                    setLocalStorage(res.data.obj.alterClassModule.moduleId, res.data.obj.alterClassModule.permissions.join(','));
                    for (let index = 0; index < res.data.obj.alterClassModule.data.length; index++) {
                        const element = res.data.obj.alterClassModule.data[index];
                        this.moduleList.alterClassModule.push({name:element.name, id: element.id});
                        if(element.entryList && element.entryList.length) {
                            element.entryList.forEach(ele => {
                                this.alterClassModule.push({
                                    name: ele.name,
                                    bgkImg: ele.bgkImg,
                                    id: ele.id,
                                    moduleId: res.data.obj.alterClassModule.moduleId,
                                    labels: ele.labels,
                                    moduleName: element.name,
                                    seriesId: ele.seriesId,
                                    playTimesZh: ele.playTimesZh
                                })
                            });
                        }
                    }
                    this.activeModule = []; // res.data.obj.activeModule.data; 动态（文章）
                    setLocalStorage(res.data.obj.activeModule.moduleId, res.data.obj.activeModule.permissions.join(','));
                    for (let index = 0; index < res.data.obj.activeModule.data.length; index++) {
                        const element = res.data.obj.activeModule.data[index];
                        this.moduleList.activeModule.push({name:element.name, id: element.id});
                        if(element.articleList && element.articleList.length) {
                            element.articleList.forEach(ele => {
                                this.activeModule.push({
                                    name: ele.name,
                                    bgkImg: ele.bgkImg,
                                    id: ele.id,
                                    moduleId: res.data.obj.activeModule.moduleId,
                                    resourceFrom: ele.resourceFrom,
                                    labels: ele.labels,
                                    moduleName: element.name,
                                    seriesId: ele.seriesId,
                                    playTimesZh: ele.playTimesZh
                                })
                            });
                        }
                    }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            linkToexpert(row) {
                this.$router.push({ 
                    name: 'subExpertGuidance',
                    params: {
                        id: row.id
                    }
                });
            },
            linkToexpertList() { // 跳转专家
                this.$router.push({ name: `expertGuidance`})
            },
            linkToDetails(index, item) { // 跳转详情
                let obj = {
                    1: 'homepage',
                    2: 'eyyb',
                    3: 'classroomTeaching', // 课堂授课
                    4: 'teacherStudyDetails', // 教师教研
                    5: 'testerTeacher', // 教师测评
                    6: 'timeFreesDetails', // 课后三点半
                    7: 'trendsDetails', // 动态
                    8: 'teachingResearchDetails', // 教案文萃
                    9: 'expertGuidance', // 专家引领
                }
                this.$router.push({
                    name: obj[index], 
                    params: {
                        id: item.id
                    },
                    query:{
                        name: `${item.name}`, 
                        seriesId: item.seriesId, // 模块下选中系列
                        seriesName: item.seriesName, // 系列名称
                        moduleId: item.moduleId // 模块
                    }
                })
            }
        }
    }
</script>

